import axios from 'axios';

const fetchPractitionerList = (
  maxNumberOfSlotsShown,
  userTimeZone,
  absoluteParamsQueryString,
  queryString,
) => {
  let url = `/call_centers/practitioners_list?mode=dfd&lite=true`;
  if (maxNumberOfSlotsShown) {
    url += `&slots_limit=${maxNumberOfSlotsShown + 1}`;
  }
  if (userTimeZone) {
    url += `&timezone=${userTimeZone}`;
  }
  if (absoluteParamsQueryString) {
    url += `&${absoluteParamsQueryString}`;
  }
  if (queryString) {
    url += `&${queryString}`;
  }
  return axios.get(url);
};

// eslint-disable-next-line import/prefer-default-export
export { fetchPractitionerList };
