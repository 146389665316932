import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useFetch from '../../../../hooks/useFetch';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import useQueryNormalizer from '../../../../hooks/useQueryNormalizer';
import FilterSection from './filter_section';
import ProviderBlock from './provider_block';
import SlotBookingModal from './slot_booking_modal';
import ProviderDrillDown from './provider_drill_down';
import styles from './slot_booking.module.scss';
import { getUserTimeZone } from '../../../../helperFunctions';
import { useModal } from '../../../../context/modal';
import {
  lockSlotForPatient,
  unlockSlotForPatient,
} from '../../../../services/appointment';
import { useUtilities } from '../../../../context/utilities';
import { getSiteDetails } from '../../../../services/practitioner';
import AppBreadcrumb from '../shared/AppBreadcrumb';
import { mssBreadcrumbs } from '../../../helpers/moduleHelper';
import { geocodeAddress } from '../../../../services/mapQuest';
import PaginateList from './shared/paginate_list';
import { fetchPractitionerList } from './shared/apis';

const userTimeZone = getUserTimeZone() || 'UTC';
const DEFAULT_LIMIT = 20;
const DEFAULT_OFFSET = 0;
const MAX_SLOTS_SHOWN = 8;

const SlotBooking = ({
  patient,
  encounter,
  stringValues = {},
  headerFilterOpen,
  handleHeaderFilterToggle,
  memberSelfServeId,
  referralInstruction,
  allowTextNotification,
  locale,
  dsboConfirmationEnabled,
  dsboCancellationConfirmationEnabled,
  cancellationPollingTimeout,
  lazyLoadEnabled,
}) => {
  const { showSpinner, hideSpinner, setSpinner } = useUtilities();

  const [, params, pushDataToUrl] = useFilterDataAsUrl();
  const initialParams = useMemo(() => params, []);

  const [defaultFilters, setDefaultFilters] = useState();
  const [availabilityData, setAvailabilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState({
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,
  });
  const [totalProviderListCount, setTotalProviderListCount] = useState(0);
  const [canLoadMoreVertically, setCanLoadMoreVertically] = useState(false);

  // specialty_id is set as empty/removed to avoid conflict with multi select array from the filter
  const [absoluteParamsQueryString] = useQueryNormalizer('', {
    ...initialParams,
    specialty_id: '',
  });
  const [queryString, formValues, handleSubmit] = useQueryNormalizer(
    '',
    defaultFilters,
  );

  const updateAvailabilityData = useCallback((apiData = {}, forceRefresh) => {
    const updatedOffset = apiData.offset + apiData.limit;
    setMetaData({ offset: updatedOffset, limit: DEFAULT_LIMIT });

    setAvailabilityData((prevData) => {
      if (forceRefresh) return apiData.providers;

      const combinedData = [...prevData, ...apiData.providers];
      return combinedData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.id === item.id && t.site_id === item.site_id),
      );
    });

    setTotalProviderListCount(apiData.total);
    setCanLoadMoreVertically(apiData.total > updatedOffset);
    setIsLoading(false);
  }, []);

  const fetchData = useCallback(
    (customMetaData = null) => {
      setIsLoading(true);
      const { offset, limit } = customMetaData || metaData;
      let query = queryString;
      if (lazyLoadEnabled) {
        query = `${query}&limit=${limit}&offset=${offset}`;
      }

      return fetchPractitionerList(
        MAX_SLOTS_SHOWN,
        userTimeZone,
        absoluteParamsQueryString,
        query,
      ).then((r) => {
        const result = r?.data;
        const isListChanged =
          lazyLoadEnabled &&
          !customMetaData &&
          totalProviderListCount > 0 &&
          result?.total !== totalProviderListCount &&
          offset > 0;

        if (isListChanged) {
          // eslint-disable-next-line no-use-before-define
          handleForceRefresh(result);
        } else {
          updateAvailabilityData(
            result,
            !lazyLoadEnabled || offset === DEFAULT_OFFSET || !!customMetaData,
          );
        }
      });
    },
    [
      absoluteParamsQueryString,
      queryString,
      lazyLoadEnabled,
      metaData,
      totalProviderListCount,
      updateAvailabilityData,
    ],
  );

  const [, filterFieldsData] = useFetch(
    `/filters/provider_listing?survey_id=${params.survey_id}`,
  );

  const handleApplyFilters = (values) => {
    if (lazyLoadEnabled) {
      setMetaData({ offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT });
    }
    handleSubmit(values); // Update queryString
  };

  const handleLoadMore = () => {
    if (lazyLoadEnabled) {
      setMetaData((prev) => ({
        offset: prev.offset || DEFAULT_OFFSET + prev.limit || DEFAULT_LIMIT,
        limit: DEFAULT_LIMIT,
      }));
      fetchData();
    }
  };

  const handleReset = useCallback(() => {
    if (lazyLoadEnabled) {
      setMetaData({ offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT });
    }
    handleSubmit(defaultFilters);
  }, [handleSubmit, defaultFilters]);

  /* If the list has changed from the backend
   (a new provider/site has been added or removed), then
   make a force refresh to get the correct data. */
  const handleForceRefresh = (result) => {
    try {
      const { offset } = result || {};
      if (lazyLoadEnabled) {
        const newMetaData = {
          offset: DEFAULT_OFFSET,
          limit: offset + DEFAULT_LIMIT,
        };
        fetchData(newMetaData);
      }
    } catch (e) {
      console.log('Error:handleForceRefresh', e.message);
    }
  };

  const initialValues = useMemo(
    () => ({ ...defaultFilters, ...formValues }),
    [formValues, defaultFilters],
  );

  // set spinner when isLoading changes
  useEffect(() => {
    setSpinner(isLoading);
  }, [setSpinner, isLoading]);

  // fetch data on load
  useEffect(() => {
    if (queryString) {
      fetchData();
    }
  }, [queryString]);

  const drillDownInitialValues = useMemo(
    () => ({
      site_ids: params.site_ids,
      reschedule: params.reschedule,

      in_person: formValues.in_person,
      virtual: formValues.virtual,
      start_date: formValues.start_date,
      end_date: formValues.end_date,
      distance: formValues.distance,
      patient_location: formValues.patient_location,
      patient_location_latitude: formValues.patient_location_latitude,
      patient_location_longitude: formValues.patient_location_longitude,
      visit_type_id: formValues.visit_type_id,
      specialty_id: formValues.specialty_id,
      start_time: formValues.start_time,
      end_time: formValues.end_time,
    }),
    [formValues, params],
  );

  const { setModal, unSetModal, alertDialogue } = useModal();
  const showSlotBookingModal = useCallback(
    async (slot, filtersApplied) => {
      showSpinner();
      const lockApiRes = await lockSlotForPatient({
        slotId: slot.id,
        patientId: patient.id,
      });
      hideSpinner();
      if (lockApiRes.data.status === 200) {
        const siteData = await getSiteDetails({
          providerId: slot.provider.id,
          siteId: slot.provider.site_id,
          patientId: patient.id,
          patientLocationStateCode: patient.address?.state?.code,
          dfd: true,
        });
        const modalFeedback = await setModal(
          <SlotBookingModal
            allowTextNotification={allowTextNotification}
            locale={locale}
            slot={slot}
            closeModal={unSetModal}
            patient={patient}
            survey_id={params.survey_id}
            fetchData={fetchData}
            stringValues={stringValues}
            filtersApplied={filtersApplied}
            memberSelfServeRecord={params.member_self_serve_record_id}
            encounter={encounter}
            siteData={siteData}
            params={params}
            referralInstruction={referralInstruction}
            customProps={{
              class: 'modal-spacing-reset',
            }}
            dsboConfirmationEnabled={dsboConfirmationEnabled}
            dsboCancellationConfirmationEnabled={
              dsboCancellationConfirmationEnabled
            }
            cancellationPollingTimeout={cancellationPollingTimeout}
          />,
        );
        if (!(modalFeedback?.data?.status === 200)) {
          unlockSlotForPatient({
            slotId: slot.id,
            patientId: patient.id,
          });
        }
      } else {
        alertDialogue(
          <div>
            We’re sorry, but the slot you’ve attempted to book is no longer
            available. Please select another slot.
          </div>,
          { okText: 'CLOSE' },
        );
      }
    },
    [
      setModal,
      unSetModal,
      patient,
      params.survey_id,
      params.member_self_serve_record_id,
      fetchData,
      stringValues,
      encounter,
    ],
  );

  const providers = availabilityData || [];

  const handleSelectProvider = useCallback(
    (provider) => {
      pushDataToUrl({
        pathway: 'member_self_serve_provider_list',
        selected_provider: provider ? provider.id : '',
        site_ids: [provider.site_id],
      });
    },
    [pushDataToUrl],
  );

  const crumbs = useMemo(
    () =>
      mssBreadcrumbs(memberSelfServeId, stringValues).filter(
        (e) => e.tag === 'home' || e.tag === 'provider_list',
      ),
    [memberSelfServeId],
  );

  // Setting the default filter values on the initial load
  useEffect(() => {
    const initializeDefaultFilters = async () => {
      showSpinner();
      let geocodedAddress;
      if (patient.patient_address_for_map) {
        geocodedAddress = await geocodeAddress(patient.patient_address_for_map);
      }
      const defaultFiltersValues = {
        search_query: '',
        distance: '10',
        start_date: moment().add(2, 'days').format('MM/DD/YYYY'),
        end_date: moment().add(9, 'days').format('MM/DD/YYYY'), // 1 week into the start date
        start_time: '',
        end_time: '',
        in_person: true,
        virtual: true,
        provider_ids: [],
        sex_id: '',
        transportation_access_id: '',
        referral_instruction_id: initialParams.referral_instruction_id,
        referral_option_id: initialParams.referral_instruction_id,
        specialty_id: [initialParams.specialty_id],
        visit_type_id: initialParams.visit_type_id,
        ...(geocodedAddress && {
          patient_location: geocodedAddress.displayString,
          patient_location_latitude: geocodedAddress.latLng.lat,
          patient_location_longitude: geocodedAddress.latLng.lng,
        }),
      };
      setDefaultFilters(defaultFiltersValues);
      handleSubmit(defaultFiltersValues);
      hideSpinner();
    };
    initializeDefaultFilters();
  }, []);

  if (!formValues.start_date) return null; // Don't render the component until the necessary values are set

  return (
    <div
      className={`${styles.panelWrapper} lucet-member-provider mss-slot-booking-wrapper`}
    >
      {!params.selected_provider ? (
        <React.Fragment>
          <div className={styles.panelLeft}>
            <AppBreadcrumb crumbs={crumbs} />
            {providers.map((provider, i) => (
              <ProviderBlock
                key={`${provider.id}--${provider.site_id}}`}
                provider={provider}
                hasMultipleSites={
                  providers.filter((p) => p.id === provider.id).length > 1
                }
                onClickSlot={(slot) =>
                  showSlotBookingModal({ ...slot, provider }, formValues)
                }
                handleSelectProvider={handleSelectProvider}
                bestMatch={i === 0}
                stringValues={stringValues}
                filtersApplied={formValues}
                maxNumberOfSlotsShown={MAX_SLOTS_SHOWN}
              />
            ))}
            {providers.length === 0 && !isLoading && (
              <h3>{stringValues.no_providers}</h3>
            )}
            {providers.length > 0 && lazyLoadEnabled && (
              <PaginateList
                availabilityData={providers}
                canLoadMoreVertically={canLoadMoreVertically}
                loadMoreProviders={handleLoadMore}
                totalProviderListCount={totalProviderListCount}
                wrapperClass="mss-listing"
                locale={locale}
              />
            )}
          </div>
          <FilterSection
            handleSubmit={handleApplyFilters}
            handleReset={handleReset}
            fieldsData={filterFieldsData}
            params={params}
            initialValues={initialValues}
            stringValues={stringValues}
            headerFilterOpen={headerFilterOpen}
            handleHeaderFilterToggle={handleHeaderFilterToggle}
          />
        </React.Fragment>
      ) : (
        <ProviderDrillDown
          providerId={params.selected_provider}
          surveyId={params.survey_id}
          siteIds={params.site_ids}
          defaultFilters={drillDownInitialValues}
          showSlotBookingModal={showSlotBookingModal}
          stringValues={stringValues}
          headerFilterOpen={headerFilterOpen}
          handleHeaderFilterToggle={handleHeaderFilterToggle}
          memberSelfServeId={memberSelfServeId}
          pathway={params.pathway}
          pdoEnabled={filterFieldsData?.pdo_flag}
        />
      )}
    </div>
  );
};

export default SlotBooking;
